import { getValueFromDto as getValue } from './_helpers.js';

export default class WasteDisposalSiteType {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.name = getValue(dto, 'name', 'string', '');
		this.sortOrder = getValue(dto, 'sortOrder', 'number', 0);
		this.active = getValue(dto, 'active', 'boolean', true);
	}
}
