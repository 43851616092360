import { url as urlHelper } from '@/helpers/helpers';
import { getIdb, idbHelpers, localChanges, localChangesInUse, localIds } from '@/idb';
import LocalChange from '@/models/LocalChange';
import LocalChangeState from '@/models/LocalChangeState';
import PaginatedList from '@/models/PaginatedList';
import WasteDisposal from '@/models/WasteDisposal';
import { DateTime } from 'luxon';
import { fetchAllPages, fetchWrap, idbResponse, isIdbResponse, offlineResponse, setPagination } from '../_helpers';
import users from './users';
import vehicles from './vehicles';
import wasteDisposalSites from './wasteDisposalSites';
import workOrders from './workOrders';

const idbStoreName = 'wasteDisposals';

async function getAllFromIdb(idb, query, localOnly) {
	const startDate = query.startDate ? DateTime.fromISO(query.startDate) : null;
	const endDate = query.endDate ? DateTime.fromISO(query.endDate) : null;
	let ids = null;
	if (query.customerLocationId) {
		ids = new Set(await idbHelpers.getFiltered(idb, 'workOrders', x => x.customerLocationId == query.customerLocationId && x.wasteDisposalId, x => x.wasteDisposalId));
	} else if (query.customerId) {
		ids = new Set(await idbHelpers.getFiltered(idb, 'workOrders', x => x.customerId == query.customerId && x.wasteDisposalId, x => x.wasteDisposalId));
	}
	const data = [];
	await idbHelpers.iterateCursor(idb, idbStoreName, x => {
		if ((!localOnly || x.id < 0) && (!ids || ids.has(x.id)) && (!query.vehicleId || x.vehicleId === query.vehicleId)) {
			x.timestamp = DateTime.fromISO(x.timestamp);
			if ((!startDate || x.timestamp >= startDate) && (!endDate || x.timestamp < endDate)) {
				data.push(x);
			}
		}
	});
	return data.sort((a, b) => b.timestamp - a.timestamp);
}

async function addLocalChanges(idb, query, data) {
	// prep for sorting
	for (const x of data) {
		await workOrders.idbReplaceLocalChanges(idb, x.workOrders);
		if (typeof x.timestamp === 'string') {
			x.timestamp = DateTime.fromISO(x.timestamp);
		}
	}
	// load local data
	const newData = await getAllFromIdb(idb, query, true);
	data.splice(data.length, 0, ...newData);
	data.sort((a, b) => b.timestamp - a.timestamp);
}

/**
 * @param {import('idb').IDBPDatabase} idb
 * @param {WasteDisposal} model
 */
async function createOrUpdateInIdb(idb, model) {
	let response;
	let data = null;
	if (model.id === 0) {
		data = JSON.parse(JSON.stringify(model));
		data.id = await localIds.getNewId(idb, idbStoreName);
		await idb.add(idbStoreName, data);
		await localChanges.add(idb, new LocalChange({ storeName: idbStoreName, id: data.id, state: LocalChangeState.added }));

		// update waste disposal site
		const idbSite = await idb.get('wasteDisposalSites', data.wasteDisposalSiteId);
		if (idbSite && idbSite.landDisposal && Array.isArray(idbSite.seasons)) {
			const timestamp = model.timestamp;
			const idbSeason = idbSite.seasons.find(x => DateTime.fromISO(x.dateOpen) <= timestamp && timestamp < DateTime.fromISO(x.dateClose)) ?? null;
			if (idbSeason) {
				idbSeason.capacityUsed += data.amountDisposed;
				idbSeason.siteFull = idbSeason.siteFull || idbSeason.capacityUsed >= idbSeason.capacityAvailable;
				await idb.put('wasteDisposalSites', idbSite);
				await localChanges.add(idb, new LocalChange({ storeName: 'wasteDisposalSites', id: idbSite.id, state: LocalChangeState.modifiedIndirectly }));
			}
		}
		// update work orders
		for (const woId of data.workOrderIds) {
			const idbWorkOrder = await idb.get('workOrders', woId);
			if (idbWorkOrder) {
				idbWorkOrder.wasteDisposalId = data.id;
				await idb.put('workOrders', idbWorkOrder);
				await localChanges.add(idb, new LocalChange({ storeName: 'workOrders', id: idbWorkOrder.id, state: LocalChangeState.modifiedIndirectly }));
			}
		}
		response = idbResponse(200);
	} else {
		const oldData = await idb.get(idbStoreName, model.id);
		if (!!oldData) {
			data = JSON.parse(JSON.stringify(model));
			await idb.put(idbStoreName, data);
			await localChanges.add(idb, new LocalChange({ storeName: idbStoreName, id: data.id, state: LocalChangeState.modified }));

			// update waste disposal site
			const prevIdbSite = await idb.get('wasteDisposalSites', oldData.wasteDisposalSiteId);
			if (prevIdbSite && prevIdbSite.landDisposal && Array.isArray(prevIdbSite.seasons)) {
				const timestamp = model.timestamp;
				const idbSeason = prevIdbSite.seasons.find(x => DateTime.fromISO(x.dateOpen) <= timestamp && timestamp < DateTime.fromISO(x.dateClose)) ?? null;
				if (idbSeason) {
					idbSeason.capacityUsed -= oldData.amountDisposed;
					idbSeason.siteFull = idbSeason.capacityUsed >= idbSeason.capacityAvailable;
					await idb.put('wasteDisposalSites', prevIdbSite);
					await localChanges.add(idb, new LocalChange({ storeName: 'wasteDisposalSites', id: prevIdbSite.id, state: LocalChangeState.modifiedIndirectly }));
				}
			}
			const idbSite = data.wasteDisposalSiteId === oldData.wasteDisposalSiteId ? prevIdbSite : await idb.get('wasteDisposalSites', data.wasteDisposalSiteId);
			if (idbSite && idbSite.landDisposal && Array.isArray(idbSite.seasons)) {
				const timestamp = model.timestamp;
				const idbSeason = idbSite.seasons.find(x => DateTime.fromISO(x.dateOpen) <= timestamp && timestamp < DateTime.fromISO(x.dateClose)) ?? null;
				if (idbSeason) {
					idbSeason.capacityUsed += data.amountDisposed;
					idbSeason.siteFull = idbSeason.siteFull || idbSeason.capacityUsed >= idbSeason.capacityAvailable;
					await idb.put('wasteDisposalSites', idbSite);
					await localChanges.add(idb, new LocalChange({ storeName: 'wasteDisposalSites', id: idbSite.id, state: LocalChangeState.modifiedIndirectly }));
				}
			}
			// update work orders
			const workOrderIds = new Set([...oldData.workOrderIds, ...data.workOrderIds]);
			for (const woId of workOrderIds) {
				if (!data.workOrderIds.includes(woId)) {
					// !inNew && inOld, remove
					const idbWorkOrder = await idb.get('workOrders', woId);
					if (idbWorkOrder) {
						idbWorkOrder.wasteDisposalId = null;
						await idb.put('workOrders', idbWorkOrder);
						await localChanges.add(idb, new LocalChange({ storeName: 'workOrders', id: idbWorkOrder.id, state: LocalChangeState.modifiedIndirectly }));
					}
				} else {
					if (!oldData.workOrderIds.includes(woId)) {
						// inNew && !inOld, update
						const idbWorkOrder = await idb.get('workOrders', woId);
						if (idbWorkOrder) {
							idbWorkOrder.wasteDisposalId = data.id;
							await idb.put('workOrders', idbWorkOrder);
							await localChanges.add(idb, new LocalChange({ storeName: 'workOrders', id: idbWorkOrder.id, state: LocalChangeState.modifiedIndirectly }));
						}
					}
					// else inNew && inOld, no change needed
				}
			}
			response = idbResponse(200);
		} else {
			response = idbResponse(404);
		}
	}
	return { data, response };
}

/**
 * @param {import('idb').IDBPDatabase} idb
 * @param {number} id
 */
async function deleteInIdb(idb, id) {
	let response;
	const oldData = await idb.get(idbStoreName, id);
	if (!!oldData) {
		await idb.delete(idbStoreName, id);
		await localChanges.add(idb, new LocalChange({ storeName: idbStoreName, id: id, state: LocalChangeState.deleted }));

		// update waste disposal site
		const prevIdbSite = await idb.get('wasteDisposalSites', oldData.wasteDisposalSiteId);
		if (prevIdbSite && prevIdbSite.landDisposal && Array.isArray(prevIdbSite.seasons)) {
			const timestamp = model.timestamp;
			const idbSeason = prevIdbSite.seasons.find(x => DateTime.fromISO(x.dateOpen) <= timestamp && timestamp < DateTime.fromISO(x.dateClose)) ?? null;
			if (idbSeason) {
				idbSeason.capacityUsed -= oldData.amountDisposed;
				idbSeason.siteFull = idbSeason.capacityUsed >= idbSeason.capacityAvailable;
				await idb.put('wasteDisposalSites', prevIdbSite);
				await localChanges.add(idb, new LocalChange({ storeName: 'wasteDisposalSites', id: prevIdbSite.id, state: LocalChangeState.modifiedIndirectly }));
			}
		}
		// update work orders
		for (const woId of oldData.workOrderIds) {
			const idbWorkOrder = await idb.get('workOrders', woId);
			if (idbWorkOrder) {
				idbWorkOrder.wasteDisposalId = null;
				await idb.put('workOrders', idbWorkOrder);
				await localChanges.add(idb, new LocalChange({ storeName: 'workOrders', id: idbWorkOrder.id, state: LocalChangeState.modifiedIndirectly }));
			}
		}
		response = idbResponse(200);
	} else {
		response = idbResponse(404);
	}
	return response;
}

export default {
	/**
	 * Get paginated waste disposals
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of WasteDisposal objects if the request was successful, otherwise a Response.
	 */
	async getPaginated({ customerId = undefined, customerLocationId = undefined, vehicleId = undefined, startDate = undefined, endDate = undefined, limit = undefined, start = undefined } = {}) {
		const query = setPagination(limit, start);
		if (typeof customerId === 'number') {
			query.customerId = customerId;
		}
		if (typeof customerLocationId === 'number') {
			query.customerLocationId = customerLocationId;
		}
		if (typeof vehicleId === 'number') {
			query.vehicleId = vehicleId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/WasteDisposals', query);
		let response, data = null;
		try {
			response = await fetchWrap(url);
			if (response.ok) {
				data = await response.json();
			}
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new PaginatedList(data, x => new WasteDisposal(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get all waste disposals
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of WasteDisposal objects if the request was successful, otherwise a Response.
	 */
	async getAll({ customerId = undefined, customerLocationId = undefined, vehicleId = undefined, startDate = undefined, endDate = undefined } = {}) {
		const query = {};
		if (typeof customerId === 'number') {
			query.customerId = customerId;
		}
		if (typeof customerLocationId === 'number') {
			query.customerLocationId = customerLocationId;
		}
		if (typeof vehicleId === 'number') {
			query.vehicleId = vehicleId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/WasteDisposals', query);
		const idb = localChangesInUse.value ? await getIdb() : null;
		let response, data = [];
		try {
			response = await fetchAllPages(url, x => data.push(x));
			if (response.ok && idb) {
				await addLocalChanges(idb, query, data);
			}
		} catch (e) {
			console.error(e)
			if (idb) {
				data = await getAllFromIdb(idb, query);
				response = data ? idbResponse(200) : idbResponse(404);
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response) && !query.customerId && !query.customerLocationId && !query.vehicleId) {
				await idbHelpers.replaceAll(idb, idbStoreName, data);
			}
			return data.map(x => new WasteDisposal(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get a waste disposal
	 * @param {Number} id WasteDisposal ID
	 * @returns (async) Returns a WasteDisposal if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		const idb = localChangesInUse.value ? await getIdb() : null;
		let response;
		let data = null;
		try {
			if (id < 0 && idb) {
				throw 'idb';
			} else {
				response = await fetchWrap('/api/WasteDisposals/' + id);
				if (response.ok) { data = await response.json(); }
			}
		} catch {
			if (idb) {
				data = await idb.get(idbStoreName, id);
				response = data ? idbResponse(200) : idbResponse(404);
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			const model = new WasteDisposal(data);
			if (isIdbResponse(response)) {
				if (model.workOrderIds.length > 0 && model.workOrderIds.length <= 100) {
					model.workOrders = await workOrders.getByIds(model.workOrderIds);
				}
				model.wasteDisposalSite = await wasteDisposalSites.getById(model.wasteDisposalSiteId);
				model.operator = await users.getById(model.operatorId);
				model.vehicle = await vehicles.getById(model.vehicleId);
			}
			return model;
		} else {
			throw response;
		}
	},
	/**
	 * Create a waste disposal
	 * @param {WasteDisposal} model waste disposal to create.
	 * @returns (async) Returns the new WasteDisposal if the request was successful, otherwise a Response.
	 */
	async create(model) {
		const idb = localChangesInUse.value ? await getIdb() : null;
		let response, data;
		try {
			response = await fetchWrap('/api/WasteDisposals', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
			if (response.ok) { data = await response.json(); }
		} catch {
			if (idb) {
				const result = await createOrUpdateInIdb(idb, model);
				data = result.data;
				response = result.response;
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			if (!isIdbResponse(response) && idb) {
				await localIds.addLocalIdMap(idb, idbStoreName, model.id, data.id);
				if (model.id < 0) { await idb.delete(idbStoreName, model.id); }
				await idb.put(idbStoreName, data);
				await localChanges.deleteChange(idb, LocalChange.getKey(idbStoreName, model.id));
			}
			return new WasteDisposal(data);
		} else {
			return response;
		}
	},
	/**
	 * Update a waste disposal
	 * @param {WasteDisposal} model waste disposal to update.
	 * @returns (async) Returns the new WasteDisposal if the request was successful, otherwise a Response.
	 */
	async update(model) {
		if (model.id <= 0) return this.create(model);
		const idb = localChangesInUse.value ? await getIdb() : null;
		let response;
		try {
			response = await fetchWrap('/api/WasteDisposals/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			if (idb) {
				const result = await createOrUpdateInIdb(idb, model);
				response = result.response;
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			if (!isIdbResponse(response) && idb) {
				if (model.id < 0) { await idb.delete(idbStoreName, model.id); }
				await idb.put(idbStoreName, JSON.parse(JSON.stringify(model)));
				await localChanges.deleteChange(idb, LocalChange.getKey(idbStoreName, model.id));
			}
		}
		return response;
	},
	/**
	 * Delete a waste disposal
	 * @param {Number} id WasteDisposal ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the waste disposal could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		const idb = localChangesInUse.value ? await getIdb() : null;
		let response;
		let useIdb = false;
		try {
			if (id > 0) {
				response = await fetchWrap('/api/WasteDisposals/' + id, { method: 'DELETE' });
			} else {
				useIdb = true;
			}
		} catch {
			useIdb = true;
			response = offlineResponse();
		}
		if (useIdb && !!idb) {
			response = await deleteInIdb(idb, id);
		}
		if (response.ok || response.status === 404) {
			if (!isIdbResponse(response) && idb) {
				await idb.delete(idbStoreName, id);
				await localChanges.deleteChange(idb, LocalChange.getKey(idbStoreName, id));
			}
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	}
};
